export default defineAppConfig({
  // TODO: Añadir urls bien
  helpCenter: {
    es: 'https://help.nutritienda.com/hc/es-es',
    it: 'https://help.nutritienda.com/hc/it',
    be: 'https://help.nutritienda.com/hc/fr-be',
    fr: 'https://help.nutritienda.com/hc/fr-fr',
  },
  contact_us: {
    es: 'https://help.perfumeriassanremo.es/hc/es-es/articles/16602405930258-Contacta-con-nosotros',
    pt: 'https://help.perfumeriassanremo.es/hc/pt/articles/16602405930258-Contacta-connosco',
    fr: 'https://help.perfumeriassanremo.es/hc/fr-fr/articles/16602405930258-Nous-contacter',
    it: 'https://help.perfumeriassanremo.es/hc/it/articles/16602405930258-Contattaci',
    de: 'https://help.perfumeriassanremo.es/hc/de/articles/16602405930258-Kontaktieren-Sie-uns',
    at: 'https://help.perfumeriassanremo.es/hc/de-at/articles/16602405930258-Kontaktieren-Sie-uns',
    be: 'https://help.perfumeriassanremo.es/hc/fr-be/articles/16602405930258-Nous-contacter',
  },
  physical_stores: {
    es: '/es/tiendas',
    pt: 'https://help.perfumeriassanremo.es/hc/pt/articles/24904718569234-Onde-est%C3%A3o-as-lojas-f%C3%ADsicas',
    fr: 'https://help.perfumeriassanremo.es/hc/fr-fr/articles/24904718569234-O%C3%B9-sont-les-magasins-physiques',
    it: 'https://help.perfumeriassanremo.es/hc/it/articles/24904718569234-Dove-si-trovano-i-negozi-fisici',
    de: 'https://help.perfumeriassanremo.es/hc/de/articles/24904718569234-Wo-befinden-sich-die-physischen-Gesch%C3%A4fte',
    at: 'https://help.perfumeriassanremo.es/hc/de-at/articles/24904718569234-Wo-befinden-sich-die-physischen-Gesch%C3%A4fte',
    be: 'https://help.perfumeriassanremo.es/hc/fr-be/articles/24904718569234-O%C3%B9-sont-les-magasins-physiques',
  },
  claims: {
    es: 'https://help.perfumeriassanremo.es/hc/es-es/articles/16602365594130-Como-hacer-una-reclamaci%C3%B3n',
    pt: 'https://help.perfumeriassanremo.es/hc/pt/articles/16602365594130-Como-tramitar-uma-reclama%C3%A7%C3%A3o',
    fr: 'https://help.perfumeriassanremo.es/hc/fr-fr/articles/16602365594130-Comment-d%C3%A9poser-une-r%C3%A9clamation',
    it: 'https://help.perfumeriassanremo.es/hc/it/articles/16602365594130-Come-presentare-un-reclamo',
    de: 'https://help.perfumeriassanremo.es/hc/de/articles/16602365594130-Beschwerde-einreichen',
    at: 'https://help.perfumeriassanremo.es/hc/de-at/articles/16602365594130-Beschwerde-einreichen',
    be: 'https://help.perfumeriassanremo.es/hc/fr-be/articles/16602365594130-Comment-d%C3%A9poser-une-r%C3%A9clamation',
  },
  privacy: {
    es: 'https://help.perfumeriassanremo.es/hc/es-es/articles/18549303751570-Pol%C3%ADtica-de-privacidad',
    pt: 'https://help.perfumeriassanremo.es/hc/pt/articles/18549303751570-Pol%C3%ADtica-de-Privacidade',
    fr: 'https://help.perfumeriassanremo.es/hc/fr-fr/articles/18549303751570-Politique-de-Confidentialit%C3%A9',
    it: 'https://help.perfumeriassanremo.es/hc/it/articles/18549303751570-Politica-sulla-privacy',
    de: 'https://help.perfumeriassanremo.es/hc/de/articles/18549303751570-Datenschutzpolitik',
    at: 'https://help.perfumeriassanremo.es/hc/de-at/articles/18549303751570-Datenschutzpolitik',
    be: 'https://help.perfumeriassanremo.es/hc/fr-be/articles/18549303751570-Politique-de-Confidentialit%C3%A9',
  },
  general_conditions: {
    es: 'https://help.perfumeriassanremo.es/hc/es-es/articles/18343748228370-Condiciones-generales',
    pt: 'https://help.perfumeriassanremo.es/hc/pt/articles/18343748228370-Condi%C3%A7%C3%B5es-gerais',
    fr: 'https://help.perfumeriassanremo.es/hc/fr-fr/articles/18343748228370-Conditions-g%C3%A9n%C3%A9rales',
    it: 'https://help.perfumeriassanremo.es/hc/it/articles/18343748228370-Condizioni-generali',
    de: 'https://help.perfumeriassanremo.es/hc/de/articles/18343748228370-Allgemeine-Gesch%C3%A4ftsbedingungen',
    at: 'https://help.perfumeriassanremo.es/hc/de-at/articles/18343748228370-Allgemeine-Gesch%C3%A4ftsbedingungen',
    be: 'https://help.perfumeriassanremo.es/hc/fr-be/articles/18343748228370-Conditions-g%C3%A9n%C3%A9rales',
  },
  returns: {
    es: 'https://help.perfumeriassanremo.es/hc/es-es/articles/16602269396114-Devoluci%C3%B3n',
    pt: 'https://help.perfumeriassanremo.es/hc/pt/articles/16602269396114-Devolu%C3%A7%C3%A3o',
    fr: 'https://help.perfumeriassanremo.es/hc/fr-fr/articles/16602269396114-Retour',
    it: 'https://help.perfumeriassanremo.es/hc/it/articles/16602269396114-Reso',
    de: 'https://help.perfumeriassanremo.es/hc/de/articles/16602269396114-R%C3%BCckgabe',
    at: 'https://help.perfumeriassanremo.es/hc/de-at/articles/16602269396114-R%C3%BCckgabe',
    be: 'https://help.perfumeriassanremo.es/hc/fr-be/articles/16602269396114-Retour',
  },
  delivery: {
    es: 'https://help.perfumeriassanremo.es/hc/es-es/articles/16585163913490-Plazos-de-entrega',
    pt: 'https://help.perfumeriassanremo.es/hc/pt/articles/16585163913490-Prazos-de-entrega',
    fr: 'https://help.perfumeriassanremo.es/hc/fr-fr/articles/16585163913490-D%C3%A9lais-de-livraison',
    it: 'https://help.perfumeriassanremo.es/hc/it/articles/16585163913490-Termini-di-consegna',
    de: 'https://help.perfumeriassanremo.es/hc/de/articles/16585163913490-Lieferzeiten',
    at: 'https://help.perfumeriassanremo.es/hc/de-at/articles/16585163913490-Lieferzeiten',
    be: 'https://help.perfumeriassanremo.es/hc/fr-be/articles/16585163913490-D%C3%A9lais-de-livraison',
  },
  shipping_costs: {
    es: 'https://help.perfumeriassanremo.es/hc/es-es/articles/16585172049426-Gastos-de-env%C3%ADo',
    pt: 'https://help.perfumeriassanremo.es/hc/pt/articles/16585172049426-Despesas-de-envio',
    fr: 'https://help.perfumeriassanremo.es/hc/fr-fr/articles/16585172049426-Frais-de-port',
    it: 'https://help.perfumeriassanremo.es/hc/it/articles/16585172049426-Spese-di-spedizione',
    de: 'https://help.perfumeriassanremo.es/hc/de/articles/16585172049426-Versandkosten',
    at: 'https://help.perfumeriassanremo.es/hc/de-at/articles/16585172049426-Versandkosten',
    be: 'https://help.perfumeriassanremo.es/hc/fr-be/articles/16585172049426-Frais-de-Port',
  },
  secure_payment: {
    es: 'https://help.perfumeriassanremo.es/hc/es-es/articles/16585178272914-Formas-de-pago',
    pt: 'https://help.perfumeriassanremo.es/hc/pt/articles/16585178272914-Formas-de-pagamento',
    fr: 'https://help.perfumeriassanremo.es/hc/fr-fr/articles/16585178272914-Modes-de-paiement',
    it: 'https://help.perfumeriassanremo.es/hc/it/articles/16585178272914-Modalit%C3%A0-di-pagamento',
    de: 'https://help.perfumeriassanremo.es/hc/de/articles/16585178272914-Zahlnungsarten',
    at: 'https://help.perfumeriassanremo.es/hc/de-at/articles/16585178272914-Zahlnungsarten',
    be: 'https://help.perfumeriassanremo.es/hc/fr-be/articles/16585178272914-Modes-de-paiement',
  },
})
